<template>
    <div>
        <div class="confirmation-box">
			<img src='/assets/images/icons/congratulations.png'>
			<h1>مبروك
				{{user.name}}
			</h1>
			<p v-if="subscriptionStatus">
				لقد حصلت على
				<span>الإشتراك
					{{subscriptionStatus.plan.name}}
				</span>
			</p>
			<template v-if="!isUpsell()">
				<div v-if="subscriptionStatus && subscriptionStatus.type !== 'free'" class="confirm-message">
					اشتراكك في تطبيق مختصر سوف يتجدد تلقائياً في تاريخ
					<span class="text-orange bold" v-if="subscriptionStatus">{{customFormatter(subscriptionStatus.next_charge_date)}}</span>
					في حال رغبت في إيقاف الإشتراك الرجاء الإلغاء قبل تاريخ التجديد
					<span class="text-orange bold" v-if="subscriptionStatus">{{customFormatter(subscriptionStatus.next_charge_date)}}</span>
				</div>
				<div v-else class="confirm-message">
					اشتراكك المجاني في تطبيق مختصر سوف ينتهي بتاريخ
					<span class="text-orange bold" v-if="subscriptionStatus">{{customFormatter(subscriptionStatus.next_charge_date)}}</span>
				</div>
			</template>
			<div class="confirm-contact">
				لقد تم إرسال رسالة تأكيد بالإشتراك إالى
				<div class="confirm-email">
					{{user.email}}
				</div>
			</div>
		</div>
		<div class="row align-items-center">
			<div class="col-6">
				<div class="confirm-img">
					<img src='/assets/images/backgrounds/confirmation.png'>
				</div>
			</div>
			<div class="col-6 download">
				<h3>حمل التطبيق</h3>
				<div class="download-btns">
					<a :href="config.android_url" target="blank">
						<img src='assets/images/app-store/logos_google-play-icon.png' />
						Android App on<br/> <span>Google Play</span>

					</a>
					<a :href="config.ios_url" target="blank">
						<img src='assets/images/app-store/app-store.png' />
						Avaliable on <br/> the <span>App Store</span>

					</a>
				</div>
			</div>
		</div>
		<div class="contact-footer text-center">
			بعد تحميل التطبيق الرجاء تسجيل الدخول
			<span>بنفس الايميل والرقم السري</span>
			المستخدمة خلال عملية إنشاء الحساب
		</div>
    </div>
</template>
<script>
import {getSubscriptionStatus} from '@/apis/services'
import moment from 'moment';

export default {
	data() {
		return {
			subscriptionStatus: null,
			config: {}
		}
	},
	computed: {
		user () {
			return this.$store.state.user
		},
	},
	methods: {
		customFormatter(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        getSubscription() {
            getSubscriptionStatus().then( res => {
                if (res) {
					this.subscriptionStatus = res.data.data
                }
            })
        },
		isUpsell() {
            return this.subscriptionStatus?.plan?.product_id == "UPSELL"
        }
    },
	mounted() {
		this.getSubscription()
		localStorage.removeItem('couponCode')
    this.config = this.$store.state.homeInfo.config
    }
}
</script>
<style>
a {
	text-decoration: none !important;
}
</style>