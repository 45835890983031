<template>
    <div class='error-page'>
        <div class="error-icon text-center">
            <img src='assets/images/icons/error.png'>
            <h1>فشلت عملية الدفع</h1>
            <p>
                لم نستطع معالجة عملية الدفع يرجى مراجعة معلومات الدفع الخاصة بك والمحاولة مرة أخرى    
            </p>
            <div>
                <button class="btn-main d-block" @click="tryAgain"> المحاولة مرة أخرى</button>
                <button class="btn-main btn-plain d-block" @click="cancelOrder"> إلغاء</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        tryAgain() {
            this.$router.push({ path: 'register-pay' })
        },
        cancelOrder() {
            this.$router.push({ path: '/' })
        }
    }
}
</script>
<style scoped lang='scss'>

</style>