<template>
<div class="confirmation-page" :class="{'error-mode': page == 'FailedPage'}">
    <component :is='page' />
</div>
</template>
<script>
import SuccessPage from "./SuccessPage"
import FailedPage from "./FailedPage"

export default {
    components: {
        SuccessPage,
        FailedPage
    },
    data() {
        return {
            page: 'FailedPage'
        }
    },
   
    mounted() {
        if (this.$route.query) {
            if (this.$route.query.status=='success') {
                this.page = 'SuccessPage'
            } else {
                this.page = 'FailedPage'
            }
        }
    }
}
</script>